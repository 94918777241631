<template>
  <div class="top-bar" :class="[{ big: bigtitle && !$store.state.isPC }, { noborder: noboder }]" ref="topMenu">
    <div class="pc-top">
      <div class="pc-top-content">
        <div class="statusBar" ref="statusBar"></div>
        <div class="bar-content">
          <div class="left-box">
            <button v-show="showBack" @click="goback()">
              <i class="icon-arrowLeft"></i>
              <span class="Bold font-18" v-if="$store.state.isPC">Back</span>
            </button>
            <button v-show="showClose" @click="closeEvent()">
              <i class="icon-close"></i>
            </button>
          </div>
          <div class="topbar-title" v-show="!bigtitle || $store.state.isPC">
            <span v-if="!$store.state.isPC">{{ title }}</span>
            <span v-if="$store.state.isPC"><img :src="logo" class="logo" /></span>
          </div>
          <div class="right-box" v-if="!bigtitle || $store.state.isPC">
            <button class="font-14" v-if="rightTitle" @click="rightevent()">
              {{ rightTitle }}
            </button>
            <button v-if="share && !$store.state.isPC" @click="shareBtnEvent()">
              <i class="icon-share"></i>
            </button>
            <button v-if="$store.state.isPC && $store.state.form.token" @click="homeEvent()">
              <img class="homeBtn" src="../assets/home.png" />
            </button>
          </div>
        </div>
        <div class="big-content" v-if="bigtitle && !$store.state.isPC">
          <div class="big-title" :class="{ 'letter-ios': $store.state.deviceType == 1 }">{{ title }}</div>
          <div class="right-box">
            <button class="font-14" v-if="rightTitle" @click="rightevent()">
              {{ rightTitle }}
            </button>
            <button v-if="share" @click="shareBtnEvent()">
              <i class="icon-share"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "../config";
export default {
  name: "topbarPage",
  mixins: [config.globalMixin],
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
  props: [
    "title",
    "rightTitle",
    "showClose",
    "showBack",
    "logout",
    "share",
    "referralTip",
    "bigtitle",
    "isold",
    "backevent",
    "rightevent",
    "noboder",
    "shareData",
  ],

  methods: {
    goback: function () {
      if (this.backevent) {
        this.backevent();
        return;
      }
      this.$router.go(-1);
    },
    rightEvent: function () {
      console.log(1);
    },
    closeEvent: function () {
      if (this.backevent) {
        this.backevent();
      } else {
        this.$router.go(-1);
      }
    },
    logoutEvent: function () {
      console.log(1);
    },
    shareBtnEvent: function () {
      this.shareEvent(this.shareData);
    },
    tipEvent: function () {
      console.log(1);
    },
    homeEvent: function () {
      this.$router.push({
        path: "/main/landing",
      });
    },
  },
  mounted: function () {
    // if (store.state.inApp == 1) {
    // }
  },
};
</script>
<style lang="scss" scoped></style>
