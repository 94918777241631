<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <div class="referral-top-image" v-if="!$store.state.isPC">
        <span class="back" @click="backEvent">
          <i class="icon-arrowLeft"></i>
        </span>
      </div>
      <topbar :bigtitle="true" title="Edit Profile" :showBack="true" :backevent="backEvent" v-if="$store.state.isPC"></topbar>
      <div class="pc-box big pc-box2">
        <div class="pc-min-content-pct">
          <div class="pc-page-title">Refer a Friend</div>
          <div class="referral-page">
            <div class="referral-img" v-if="$store.state.isPC">
              <img src="../assets/001-copy@3x.png" />
            </div>
            <div>
              <p class="font-18 pc-font-28 font-b main-color" :class="{ 'letter-ios': $store.state.deviceType == 1 }">Your Referral Code</p>
              <p class="code-box">
                <span class="font-18 main-color Medium">{{ $store.state.form.userInfo.referralCode }}</span>
                <span class="font-16 sub-color" style="cursor: pointer" @click="copyEvent">Copy</span>
              </p>
              <p class="font-16 label-color m-b-20 Helveticaneue">
                Know someone who would love to receive exclusive savings and support their local club, school or charity every time they shop? Click the “Refer a Friend” button and be rewarded for
                growing the Ritchies community.
              </p>
              <p class="font-18 pc-font-28 font-b main-color m-b-10" :class="{ 'letter-ios': $store.state.deviceType == 1 }">How it works?</p>
              <p class="font-16 label-color m-b-20 Helveticaneue">
                Use the “Refer a Friend” button to directly refer anyone you know - they'll receive a message with a link to download and register the Ritchies app.When setting up their account they
                will be able to enter your referral code. Once registration is complete they’re ready to shop! After they’ve made their first purchase at a Ritchies store, you’ll receive a $5*
                discount to use on your next shop. You can Refer as many friends as you like! <span class="main-color" @click="jumpUrl">*Terms and conditions apply</span>
              </p>
              <!-- <p class="font-18 pc-font-28 font-b main-color m-b-10" :class="{ 'letter-ios': $store.state.deviceType == 1 }">Have lots of friends?</p> -->
              <!-- <p class="font-16 label-color m-b-20 Helveticaneue">Feel free to refer as many people as you like!</p> -->
              <ion-button expand="block" class="Bold" @click="shareFriendEvent">Refer a Friend</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import config from "../config";
import { Clipboard } from "@capacitor/clipboard";
import topbar from "@/components/topbar.vue";

export default {
  name: "ReferralPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, IonButton, topbar },
  data() {
    return {
      shareData: {},
    };
  },
  methods: {
    backEvent() {
      this.$router.go(-1);
    },
    jumpUrl() {
      this.openInAppBrowser({
        webUrl: "https://www.ritchies.com.au/referafriend",
        bannerType: 1,
      });
      this.sendGAEvent("Click", "Referral", "Terms and conditions");

      //   window.open("https://www.ritchies.com.au/referafriend");
    },
    async copyEvent() {
      await Clipboard.write({
        string: this.$store.state.form.userInfo.referralCode,
      });
      this.successToast("Copied\nsuccessfully");
      this.sendGAEvent("Click", "Referral", "Copy");
    },
    shareFriendEvent() {
      this.shareData = {
        title: "You're invited to download the Ritchies Card app and receive a $10 discount when you join!",
        description: `Hi, I'm inviting you to download the Ritchies app. Receive a $10* discount just by registering with my referral code [${this.$store.state.form.userInfo.referralCode}]. There are also LOADS of benefits including member-exclusive savings and the ability to support your favourite club, school or charity. Click this link to join! \n *Terms & conditions apply`,
        image: "",
      };
      this.shareEvent(this.shareData);
      this.sendGAEvent("Click", "Referral", "Share");
    },
  },
  ionViewWillEnter() {
    this.$store.state.exitApp = false;
    this.sendGAPageView("Refer a Friend");
  },
};
</script>
